import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "Resources/Languages/i18n";

ReactDOM.render(
  <App />,

  document.getElementById("root")
);
