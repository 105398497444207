import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

const GlobalStyles = createGlobalStyle`
${reset}
a{
    text-decoration:none;
    color: inherit;
}
*{
    box-sizing:boerder-box;
}
body{
    font-family: 'Noto Sans KR', sans-serif;
    padding: 0;
    margin: 0;
    background-color: #F4F6F8;
}
`;

export default GlobalStyles;
