import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEn from "Resources/Languages/translation.en";
import translationKo from "Resources/Languages/translation.ko";
import translationJp from "Resources/Languages/translation.jp";

const resource = {
  en: {
    translation: translationEn,
  },
  ko: {
    translation: translationKo,
  },
  jp: {
    translation: translationJp,
  },
};

i18n.use(initReactI18next).init({
  resources: resource,
  lng: "en",
  fallbackLng: "en",
  debug: false,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
