import React, { useContext } from "react";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import GlobalContext from "Context/GlobalContext";
import Signin from "Pages/Signin";
import Signup from "Pages/Signup";
import Main from "Pages/Main";

const LoginRoutes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Main} />
        <Redirect from="*" to="/" />
      </Switch>
    </BrowserRouter>
  );
};

const LogoutRoutes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Signin} />
        <Route exact path="/signup" component={Signup} />
        <Redirect from="*" to="/" />
      </Switch>
    </BrowserRouter>
  );
};

const AppRouter = () => {
  const { isLogin, setIsLogin } = useContext(GlobalContext);
  return isLogin ? <LoginRoutes /> : <LogoutRoutes />;
};

export default AppRouter;
