import React, { useContext, useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import NativeSelect from "@material-ui/core/NativeSelect";
import GlobalContext from "Context/GlobalContext";
import { makeStyles } from "@material-ui/core/styles";
import FirstImage from "Resources/Images/1.jpeg";
import SecondImage from "Resources/Images/2.jpeg";
import ThirdImage from "Resources/Images/3.jpeg";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  Container: {},
  AppBar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "primary",
    width: "100%",
    height: "50px",
    padding: theme.spacing(0, 1, 0, 2),
    margin: "15px 0",
    borderRadius: "10px",
  },
  Toolbar: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  firstImage: {
    width: "100%",
    borderRadius: "10px",
    marginBottom: "10px",
    cursor: "pointer",
  },
  secondImage: {
    width: "100%",
    borderRadius: "10px",
    marginBottom: "10px",
    cursor: "pointer",
  },
  thirdImage: {
    width: "100%",
    borderRadius: "10px",
    marginBottom: "10px",
    cursor: "pointer",
  },
  selectEmpty: {
    marginLeft: "13px",
    paddingLeft: "8px",
    color: "white",
    "&:before": {
      border: "none",
      borderColor: "white",
    },
    "&:after": {
      border: "none",
      borderColor: "white",
    },
  },
  helpText: {
    fontSize: "16px",
  },
}));

const Main = ({ history }) => {
  const [language, setLanguage] = useState("en");
  const [open, setOpen] = useState(false);
  const { isLogin, setIsLogin } = useContext(GlobalContext);

  const classes = useStyles();

  const { t, i18n } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    sessionStorage.removeItem("token");
    setIsLogin(false);
    history.push("/");
    setLanguage(language);
    changeLanguage(language);
  };

  const changeLanguage = (item) => {
    i18n.changeLanguage(item);
  };

  const onChangeLanguage = (e) => {
    setLanguage(e.target.value);
    changeLanguage(e.target.value);
  };

  useEffect(() => {
    changeLanguage(language);
  }, []);

  return (
    <>
      <Container className={classes.Container} maxWidth="sm">
        <Box sx={{ flexGrow: 1 }}>
          <AppBar className={classes.AppBar} position="static">
            <Toolbar className={classes.Toolbar}>
              <div>
                <IconButton
                  className={classes.IconButton}
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                >
                  <MenuIcon />
                </IconButton>
                <NativeSelect
                  value={language}
                  onChange={onChangeLanguage}
                  name="language"
                  className={classes.selectEmpty}
                >
                  <option value={"en"}>{t("English")}</option>
                  <option value={"ko"}>{t("Korean")}</option>
                  <option value={"jp"}>{t("Japan")}</option>
                </NativeSelect>
              </div>
              <Button onClick={handleClickOpen} color="inherit">
                {t("Logout")}
              </Button>
            </Toolbar>
          </AppBar>
        </Box>

        <div>
          <img
            className={classes.firstImage}
            src={FirstImage}
            onClick={() => {
              window.open(FirstImage);
            }}
          />
          <img
            className={classes.secondImage}
            src={SecondImage}
            onClick={() => {
              window.open(SecondImage);
            }}
          />
          <img
            className={classes.thirdImage}
            src={ThirdImage}
            onClick={() => {
              window.open(ThirdImage);
            }}
          />
        </div>

        <div style={{ marginBottom: "20px" }}>
          <Typography className={classes.helpText}>{t("<Notice>")}</Typography>
          <Typography className={classes.helpText}>{t("ESS platform renewal version is in preparation.")}</Typography>
          <Typography className={classes.helpText}>
            {t("The new platform will be available soon and will be fully fixed.")}
          </Typography>
          <Typography className={classes.helpText}>{t("1. Design renewal")}</Typography>
          <Typography className={classes.helpText}>
            {t("- UIUX, which was not seen before, will be reorganized to make it easier to see.")}
          </Typography>
          <Typography className={classes.helpText}>{t("2. Launch of ESS-linked shopping mall")}</Typography>
          <Typography className={classes.helpText}>
            {t("- A shopping mall where you can purchase products at ESS will be released.")}
          </Typography>
          <Typography className={classes.helpText}>{t("3. Compensation plan renewal")}</Typography>
          <Typography className={classes.helpText}>
            {t("- The old version of the reward plan will be revised and updated to the new plan.")}
          </Typography>
          <Typography className={classes.helpText}>{t("4. Existing compensation application")}</Typography>
          <Typography className={classes.helpText}>
            {t("- Compensation that was not previously applied will be paid retroactively.")}
          </Typography>
          <Typography className={classes.helpText}>
            {t("Expect a new ESS platform that will change everywhere.")}
          </Typography>
          <Typography className={classes.helpText}>
            {t("* The existing user's DB is also being moved to the new platform.")}
          </Typography>
          <Typography className={classes.helpText}>
            {t("Soon existing users will also be able to log in with their existing ID on the new platform.")}
          </Typography>
          <Typography className={classes.helpText}>{t("* Fixed all small bugs.")}</Typography>
          <Typography className={classes.helpText}>
            {t("* All the parts that have not been applied so far will be applied.")}
          </Typography>
          <Typography className={classes.helpText}>
            {t("* New subscribers can also open the platform and purchase packages.")}
          </Typography>
          <Typography className={classes.helpText}>
            {t(
              "However, although you can now purchase the package with the old version, this will be automatically linked to the new version."
            )}
          </Typography>
        </div>

        <Dialog maxWidth="lg" open={open}>
          <DialogTitle className={classes.Dialog}>
            <span style={{ fontWeight: "bold" }}>{t("Notification")}</span>
          </DialogTitle>
          <DialogContent style={{ height: "100px", width: "350px" }}>
            <DialogContentText
              style={{
                padding: "15px 10px",
                borderTop: "1px solid rgba(0,0,0,0.15)",
                borderBottom: "1px solid rgba(0,0,0,0.15)",
                color: "black",
                fontWeight: "400",
              }}
            >
              {t("Are you sure you want to log out?")}
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ padding: "0px 20px 20px 0px" }}>
            <Button variant="contained" color="primary" onClick={handleLogout} autoFocus>
              {t("OK")}
            </Button>
            <Button variant="contained" color="primary" onClick={handleClose} autoFocus>
              {t("NO")}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
};

export default Main;
