import React, { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "Routes";
import GlobalContext from "Context/GlobalContext";
import GlobalStyles from "Styles/GlobalStyles";

const App = () => {
  const [token, setToken] = useState("");
  const [isLogin, setIsLogin] = useState(Boolean(sessionStorage.getItem("token")));
  const initialize = {
    token,
    setToken,
    isLogin,
    setIsLogin,
  };

  return (
    <>
      <Router>
        <GlobalStyles />
        <GlobalContext.Provider value={initialize}>
          <Routes />
        </GlobalContext.Provider>
      </Router>
    </>
  );
};

export default App;
