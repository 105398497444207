import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import { makeStyles } from "@material-ui/core/styles";
import NativeSelect from "@material-ui/core/NativeSelect";
import Logo from "Resources/Images/LogoImage.png";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    width: "100vw",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F4F6F8",
  },
  container: {
    width: "350px",
    marginTop: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  titleDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  logo: {
    width: "180px",
  },
  avatar: {
    margin: theme.spacing(0),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(0),
  },
  formDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  textfield: {
    width: "100%",
    marginBottom: "15px",
  },
  submit: {
    width: "250px",
    margin: theme.spacing(2, 0, 2),
  },
  submitDiv: {
    display: "flex",
    justifyContent: "center",
  },
  signup: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
    marginBottom: "30px",
  },
  selectEmpty: {
    paddingLeft: "10px",
  },
  Dialog: {
    padding: theme.spacing(3, 0, 0, 4),
    fontWeight: "900",
  },
}));

const SignUp = ({ history }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [language, setLanguage] = useState("en");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [detailAddress, setDetailAddress] = useState("");
  const [recommend, setRecommend] = useState("");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const server = process.env.REACT_APP_SERVER;

  const classes = useStyles();

  const { t, i18n } = useTranslation();

  const changeLanguage = (item) => {
    i18n.changeLanguage(item);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSignin = () => {
    history.push("/");
  };

  const onChangeLanguage = (e) => {
    setLanguage(e.target.value);
    changeLanguage(e.target.value);
  };

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const onChangeRepeatPassword = (e) => {
    setRepeatPassword(e.target.value);
  };

  const onChangePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
  };

  const onChangeAddress = (e) => {
    setAddress(e.target.value);
  };

  const onChangeDetailAddress = (e) => {
    setDetailAddress(e.target.value);
  };

  const onChangeRecommend = (e) => {
    setRecommend(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const { data } = await axios.post(`${server}/login/signup`, {
      email: email,
      password: password,
      repeatPassword: repeatPassword,
      phoneNumber: phoneNumber,
      address: address,
      detailAddress: detailAddress,
      recommend: recommend,
    });
    if (data.status == true) {
      setSuccess(true);
      setMessage(data.message);
      handleClickOpen();
    } else {
      setMessage(data.message);
      handleClickOpen();
    }
  };

  useEffect(() => {
    setSuccess(false);
  }, []);

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <div className={classes.container}>
        <Dialog maxWidth="lg" open={open}>
          <DialogTitle className={classes.Dialog}>
            <span style={{ fontWeight: "bold" }}>{t("Notification")}</span>
          </DialogTitle>
          <DialogContent style={{ height: "100px", width: "350px" }}>
            <DialogContentText
              style={{
                padding: "15px 10px",
                borderTop: "1px solid rgba(0,0,0,0.15)",
                borderBottom: "1px solid rgba(0,0,0,0.15)",
                color: "black",
                fontWeight: "400",
              }}
            >
              {t(message)}
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ padding: "0px 20px 20px 0px" }}>
            <Button variant="contained" color="primary" onClick={success ? handleSignin : handleClose} autoFocus>
              {t("OK")}
            </Button>
          </DialogActions>
        </Dialog>

        <div className={classes.titleDiv}>
          <img className={classes.logo} src={Logo} />
          <div className={classes.lang}>
            <NativeSelect value={language} onChange={onChangeLanguage} name="language" className={classes.selectEmpty}>
              <option value={"en"}>{t("English")}</option>
              <option value={"ko"}>{t("Korean")}</option>
              <option value={"jp"}>{t("Japan")}</option>
            </NativeSelect>
          </div>
        </div>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography className={classes.title} component="h1" variant="h4">
          {t("Sign up")}
        </Typography>
        <form className={classes.form} onSubmit={onSubmit}>
          <div className={classes.formDiv}>
            <TextField
              className={classes.textfield}
              variant="outlined"
              required
              id="email"
              type="email"
              label={t("Email Address")}
              name="email"
              autoFocus
              value={email}
              onChange={onChangeEmail}
              inputProps={{ maxLength: 50 }}
            />

            <TextField
              className={classes.textfield}
              variant="outlined"
              required
              name="password"
              label={t("Password")}
              type="password"
              id="password"
              value={password}
              onChange={onChangePassword}
            />
            <TextField
              className={classes.textfield}
              variant="outlined"
              required
              name="password"
              label={t("Repeat Password")}
              type="password"
              id="password"
              value={repeatPassword}
              onChange={onChangeRepeatPassword}
            />
            <TextField
              className={classes.textfield}
              variant="outlined"
              required
              name="number"
              label={t("Phone Number")}
              type="text"
              id="number"
              value={phoneNumber}
              onChange={onChangePhoneNumber}
              inputProps={{ maxLength: 30 }}
            />
            <TextField
              className={classes.textfield}
              variant="outlined"
              required
              name="address"
              label={t("Address")}
              type="text"
              id="address"
              value={address}
              onChange={onChangeAddress}
            />
            <TextField
              className={classes.textfield}
              variant="outlined"
              name="detailAddress"
              label={t("Detail Address")}
              type="text"
              id="detailAddress"
              value={detailAddress}
              onChange={onChangeDetailAddress}
            />
            <TextField
              className={classes.textfield}
              variant="outlined"
              name="recommend"
              label={t("Recommend")}
              type="text"
              id="recommend"
              value={recommend}
              onChange={onChangeRecommend}
            />
          </div>
          <div className={classes.submitDiv}>
            <Button type="submit" variant="contained" color="primary" className={classes.submit}>
              {t("Sign Up")}
            </Button>
          </div>
          <Grid container className={classes.signup}>
            <Grid item>
              <Link
                href=""
                onClick={() => {
                  history.push("/");
                }}
                variant="body2"
              >
                <span>{t("Already have an account? Sign in")}</span>
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Grid>
  );
};

export default SignUp;
