import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import GlobalContext from "Context/GlobalContext";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import NativeSelect from "@material-ui/core/NativeSelect";
import Logo from "Resources/Images/LogoImage.png";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
    width: "100vw",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F4F6F8",
  },
  container: {
    width: "350px",
    padding: theme.spacing(0, 0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  titleDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginBottom: "40px",
  },
  logo: {
    width: "200px",
  },
  avatar: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(0),
  },
  submitDiv: {
    display: "flex",
    justifyContent: "center",
  },
  submit: {
    width: "240px",
    margin: theme.spacing(5, 0, 2),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  formDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  email: {
    width: "100%",
  },
  password: {
    width: "100%",
  },
  signup: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
    marginBottom: "100px",
  },
  selectEmpty: {
    paddingLeft: "10px",
  },
  Dialog: {
    padding: theme.spacing(3, 0, 0, 4),
    fontWeight: "900",
  },
}));

const SignIn = ({ history }) => {
  const { isLogin, setIsLogin, token, setToken } = useContext(GlobalContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [language, setLanguage] = useState("en");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const server = process.env.REACT_APP_SERVER;

  const classes = useStyles();

  const { t, i18n } = useTranslation();

  const changeLanguage = (item) => {
    i18n.changeLanguage(item);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSignin = () => {
    setIsLogin(Boolean(sessionStorage.getItem("token")));
    history.push("/");
  };

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const onChangeLanguage = (e) => {
    setLanguage(e.target.value);
    changeLanguage(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const { data } = await axios.post(`${server}/login/signin`, { email: email, password: password });
    console.log(data);
    if (data.status == true) {
      setSuccess(true);
      handleClickOpen();
      setMessage(data.message);
      sessionStorage.setItem("token", data.token);
      return;
    } else {
      handleClickOpen();
      setMessage(data.message);
      return;
    }
  };

  useEffect(() => {
    changeLanguage(language);
  }, []);

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <div className={classes.container}>
        <Dialog maxWidth="lg" open={open}>
          <DialogTitle className={classes.Dialog}>
            <span style={{ fontWeight: "bold" }}>{t("Notification")}</span>
          </DialogTitle>
          <DialogContent style={{ height: "100px", width: "350px" }}>
            <DialogContentText
              style={{
                padding: "15px 10px",
                borderTop: "1px solid rgba(0,0,0,0.15)",
                borderBottom: "1px solid rgba(0,0,0,0.15)",
                color: "black",
                fontWeight: "400",
              }}
            >
              {t(message)}
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ padding: "0px 20px 20px 0px" }}>
            <Button variant="contained" color="primary" onClick={success ? handleSignin : handleClose} autoFocus>
              {t("OK")}
            </Button>
          </DialogActions>
        </Dialog>

        <div className={classes.titleDiv}>
          <img className={classes.logo} src={Logo} />
          <div className={classes.lang}>
            <NativeSelect value={language} onChange={onChangeLanguage} name="language" className={classes.selectEmpty}>
              <option value={"en"}>{t("English")}</option>
              <option value={"ko"}>{t("Korean")}</option>
              <option value={"jp"}>{t("Japan")}</option>
            </NativeSelect>
          </div>
        </div>

        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography className={classes.title} component="h1" variant="h4">
          {t("Sign in")}
        </Typography>
        <form className={classes.form} onSubmit={onSubmit}>
          <div className={classes.formDiv}>
            <TextField
              className={classes.email}
              variant="outlined"
              margin="normal"
              required
              type="email"
              id="email"
              label={t("Email Address")}
              name="email"
              autoFocus
              value={email}
              onChange={onChangeEmail}
              inputProps={{ maxLength: 50 }}
            />
            <TextField
              className={classes.password}
              variant="outlined"
              margin="normal"
              required
              name="password"
              label={t("Password")}
              type="password"
              id="password"
              value={password}
              onChange={onChangePassword}
            />
          </div>
          <div className={classes.submitDiv}>
            <Button type="submit" variant="contained" color="primary" className={classes.submit}>
              {t("Sign In")}
            </Button>
          </div>
          <Grid container className={classes.signup}>
            <Link
              className={classes.link}
              href=""
              onClick={() => {
                history.push("/signup");
              }}
              variant="body2"
            >
              <span>{t("Don't have an account? Sign Up")}</span>
            </Link>
            <Link
              href=""
              onClick={() => {
                window.open("https://marketing.essentialshelf.com/");
              }}
              variant="body2"
            >
              <span>{t("Go to Marketing")}</span>
            </Link>
          </Grid>
        </form>
      </div>
    </Grid>
  );
};

export default SignIn;
